import st from "../styles/components/Footer.module.scss";
import { memo } from "react";
import useLanguage, { useDict } from "../hooks/useLanguage";
import OverEighteen from "../assets/icons/general/OverEighteen";
import Accordion from "./Accordion";
import { classnames, isDomain, resolveDomain } from "../lib/tools/helpers";
import Support from "../assets/icons/lifebuoy.jsx";
import HandShake from "../assets/icons/handshake.jsx";
import ResultFeed from "./ResultFeed";
import { useRecoilValue } from "recoil";
import inBlogAtom from "../recoil/inBlog/atom";
import Link from "next/link";
import { EVENTS, usePubSub } from "@lib/pubsub";
import AnjSeal from "./IntegrationComponents/AnjSeal";
import { NewLogo } from "@assets/icons/general/NewLogo";

const bg = "#171B34";
const addSlash = (a) => (isDomain("main") ? `/${a}` : resolveDomain("main", a));
const links = {
  about: [
    // "about-us",
    "rewards",
    "promotions",
    "provably-fair",
    "contact-us",
  ].map(addSlash),
  info: [
    "responsible-gambling",
    "accessibility",
    "code-of-ethics",
    "modern-slavery-statement",
    "complaints-policy",
    "privacy-policy#gdpr-compliance",
  ].map(addSlash),
  additional: [
    "aml",
    "sportsbook-rules",
    "cookie-policy",
    "editorial-policy",
    "disclaimer",
    "privacy-policy",
    "terms",
  ].map(addSlash),
};

const x = (
  <svg
    width="16"
    height="16"
    viewBox="0 0 18 18"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <g clipPath="url(#clip0_109_7697)">
      <path
        d="M16.5387 1.597L0.591584 7.77838C-0.0501582 8.06624 -0.267213 8.6427 0.436475 8.95555L4.52761 10.2624L14.4195 4.11746C14.9596 3.73169 15.5125 3.83455 15.0367 4.25893L6.54094 11.991L6.27406 15.2632C6.52125 15.7684 6.97385 15.7708 7.26255 15.5197L9.61303 13.2841L13.6386 16.3141C14.5736 16.8705 15.0823 16.5115 15.2835 15.4917L17.9239 2.92439C18.198 1.66914 17.7305 1.11606 16.5387 1.597Z"
        fill="white"
      />
    </g>
    <defs>
      <clipPath id="clip0_109_7697">
        <rect width="18" height="18" fill="white" />
      </clipPath>
    </defs>
  </svg>
);

const shareIcon = (
  <svg
    width="16"
    height="13"
    viewBox="0 0 18 14"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      d="M17.7321 1.70269C17.1059 1.98019 16.4331 2.16769 15.7259 2.25244C16.4556 1.81578 17.0016 1.12854 17.2619 0.318936C16.5763 0.726178 15.8259 1.01283 15.0434 1.16644C14.5172 0.604584 13.8202 0.23218 13.0606 0.107041C12.3011 -0.018098 11.5215 0.111029 10.8428 0.474375C10.1642 0.837721 9.62447 1.41496 9.3075 2.11646C8.99054 2.81797 8.91404 3.6045 9.08989 4.35394C7.70065 4.28418 6.34161 3.9231 5.10096 3.29411C3.8603 2.66513 2.76577 1.78231 1.88839 0.702936C1.58839 1.22044 1.41589 1.82044 1.41589 2.45944C1.41555 3.03468 1.55721 3.60112 1.8283 4.10848C2.09938 4.61585 2.49151 5.04846 2.96989 5.36794C2.41509 5.35028 1.87254 5.20037 1.38739 4.93069V4.97569C1.38733 5.78249 1.66641 6.56447 2.17727 7.18893C2.68814 7.8134 3.39932 8.24188 4.19014 8.40169C3.67547 8.54097 3.13589 8.56149 2.61214 8.46169C2.83526 9.1559 3.26988 9.76296 3.85516 10.1979C4.44044 10.6328 5.14707 10.8738 5.87614 10.8872C4.63851 11.8587 3.11005 12.3858 1.53664 12.3834C1.25792 12.3835 0.979444 12.3672 0.702637 12.3347C2.29974 13.3616 4.15889 13.9066 6.05764 13.9044C12.4851 13.9044 15.9989 8.58094 15.9989 3.96394C15.9989 3.81394 15.9951 3.66244 15.9884 3.51244C16.6719 3.01817 17.2618 2.40611 17.7306 1.70494L17.7321 1.70269Z"
      fill="white"
    />
  </svg>
);

export const Socials = () => {
  return (
    <>
      <a
        href="https://twitter.com/rainbetcom"
        target="_blank"
        rel="noreferrer"
        {...classnames(st, "icon")}
      >
        {shareIcon}
      </a>
      <a
        href="https://t.me/rainbetcasino"
        target="_blank"
        {...classnames(st, "icon")}
        rel="noreferrer"
      >
        {x}
      </a>
    </>
  );
};

const Title = ({ children }) => (
  <div {...classnames(st, "title")}>{children}</div>
);

const Footer = ({ feed = true, cert = true, isChallenges = false }) => {
  const L = useLanguage(["Footer"]);
  const Dict = useDict(["Footer"]);
  const text = {
    copyright: L("copyright"),
    notice1: L("notice1"),
    notice2: L("notice2"),
    about: L("about"),
    resources: L("resources"),
    legal: L("legal"),
  };

  const [about, ...aboutItems] = text.about.split(", ");
  const [info, ...infoItems] = text.resources.split(", ");
  const [additional, ...additionalItems] = text.legal.split(", ");

  const inBlog = useRecoilValue(inBlogAtom);

  const showFeed = feed && !inBlog ? <ResultFeed /> : null;
  const sports = usePubSub(EVENTS.IS_SPORTSBOOK);

  return (
    <>
      <div>
        {showFeed}

        <hr className="mt-12 transform scale-x-105" />
      </div>

      <div {...classnames(st, "footer", sports && "sports")}>
        <div {...classnames(st, "logo")}>
          <NewLogo />

          <Dict
            name="notice1"
            data-notice
            replacements={{
              LINK: <Link href="https://rainbet.com">crypto casino</Link>,
            }}
          />
          <Dict name="notice2" />

          <div
            style={{
              marginTop: 20,
            }}
          >
            <Support
              style={{
                width: 18,
                marginRight: 6,
              }}
            />
            support@rainbet.com
          </div>

          <div
            style={{
              marginTop: -15,
              marginBottom: 20,
            }}
          >
            <HandShake
              style={{
                width: 18,
                marginRight: 6,
              }}
            />
            marketing@rainbet.com
          </div>

          <div className={st["social-media"]}>
            <Socials />
          </div>
        </div>

        <div {...classnames(st, "links", "wide")}>
          <div {...classnames(st, "link-wrapper")}>
            <Title>{about}</Title>

            {aboutItems.map((item, index) => (
              <Link
                key={index}
                {...classnames(st, "link")}
                href={links.about[index]}
              >
                {item}
              </Link>
            ))}
          </div>

          <div {...classnames(st, "link-wrapper")}>
            <Title>{info.toUpperCase()}</Title>
            {infoItems.map((item, index) => (
              <Link
                key={index}
                {...classnames(st, "link")}
                href={links.info[index]}
              >
                {item}
              </Link>
            ))}
          </div>

          <div {...classnames(st, "link-wrapper")}>
            <Title>{additional.toUpperCase()}</Title>
            {additionalItems.map((item, index) => (
              <Link
                key={index}
                {...classnames(st, "link")}
                href={links.additional[index]}
              >
                {item}
              </Link>
            ))}
          </div>
        </div>

        <div {...classnames(st, "links")}>
          <Accordion
            title={<Title>{about}</Title>}
            defaultClosed
            mergedBody
            bg={bg}
          >
            <Link {...classnames(st, "link")} href={links.about[0]}>
              {about}
            </Link>

            {aboutItems.map((item, index) => (
              <Link
                key={index}
                {...classnames(st, "link")}
                href={links.about[index]}
              >
                {item}
              </Link>
            ))}
          </Accordion>

          <Accordion
            title={<Title>{info.toUpperCase()}</Title>}
            defaultClosed
            mergedBody
            bg={bg}
          >
            {infoItems.map((item, index) => (
              <Link
                key={index}
                {...classnames(st, "link")}
                href={links.info[index]}
              >
                {item}
              </Link>
            ))}
          </Accordion>

          <Accordion
            title={<Title>{additional.toUpperCase()}</Title>}
            defaultClosed
            mergedBody
            bg={bg}
          >
            {additionalItems.map((item, index) => (
              <Link
                key={index}
                {...classnames(st, "link")}
                href={links.additional[index]}
              >
                {item}
              </Link>
            ))}
          </Accordion>
        </div>

        <div className={st["gaming-badge"]}>
          <hr />

          <div style={{ marginRight: "auto" }}>
            Copyright © 2024 Rainbet.com. All Rights Reserved.
          </div>

          {cert && (
            <div className={st["eightneen-icon"]}>
              <OverEighteen width={36} height={36} />
            </div>
          )}

          {cert && <AnjSeal className={st["anj-seal"]} />}
        </div>
      </div>
    </>
  );
};

export default memo(Footer);

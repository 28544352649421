export default function OverEighteen({width, height}, props) {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width={width}
      height={height}
      viewBox="0 0 36 36"
      fill="none"
      {...props}
    >
      <path
        d="M9.91686 25.3604V13.8908C9.21932 14.2794 7.88735 14.6587 7.125 14.7503V12.6336C8.43137 12.3609 9.84654 11.5516 10.7451 10.6149H12.741V25.3604H9.91686Z"
        fill="#7179A5"
      />
      <path
        d="M18.8446 14.5306C18.8446 15.6101 19.5846 16.4053 21.1084 16.4053C22.5697 16.4053 23.3097 15.7867 23.3097 14.5574C23.3097 13.3839 22.5474 12.5596 21.0794 12.5596C19.5556 12.5596 18.8446 13.299 18.8446 14.5306ZM23.7252 21.0295C23.7252 19.4316 22.6569 18.6541 21.0503 18.6541C19.2605 18.6541 18.3843 19.6439 18.3843 21.0138C18.3843 22.4373 19.4236 23.4338 21.0907 23.4338C22.6258 23.4338 23.7252 22.6653 23.7252 21.0295ZM15.5781 21.1525C15.5781 19.2055 16.9217 18.0385 18.0729 17.5465C16.8904 16.8711 16.1795 15.9413 16.1795 14.4566C16.1795 12.0348 17.9306 10.3802 21.1464 10.3802C24.0849 10.3802 25.9748 11.9721 25.9748 14.4186C25.9748 16.0733 25.1095 16.9695 24.0411 17.4077C25.212 17.8213 26.5158 19.1089 26.5158 21.0607C26.5158 24.0192 24.1744 25.6289 20.9205 25.6289C17.9666 25.6289 15.5781 24.0036 15.5781 21.1525Z"
        fill="#7179A5"
      />
      <path
        d="M30.4062 13.637V5.04528H32.9998V13.637H30.4062Z"
        fill="#7179A5"
      />
      <path
        d="M27.4082 10.6377V8.044H35.9998V10.6377H27.4082Z"
        fill="#7179A5"
      />
      <path
        d="M17.7022 35.4045C7.94118 35.4045 0 27.4634 0 17.7024C0 7.94135 7.94118 0.000171661 17.7022 0.000171661C21.4957 0.000171661 25.1127 1.18285 28.1627 3.42022L26.3987 5.82519C23.8638 3.96584 20.8565 2.98301 17.7022 2.98301C9.58588 2.98301 2.98275 9.58605 2.98275 17.7024C2.98275 25.8187 9.58588 32.4217 17.7022 32.4217C25.8185 32.4217 32.4215 25.8187 32.4215 17.7024C32.4215 17.2439 32.4003 16.782 32.3585 16.3293L35.3286 16.0547C35.3789 16.5983 35.4044 17.1527 35.4044 17.7024C35.4044 27.4634 27.4632 35.4045 17.7022 35.4045Z"
        fill="#7179A5"
      />
    </svg>
  );
}

import { useState, memo, useEffect, useMemo, useCallback } from "react";

import {
  classnames,
  handleCatchPopup,
  trimToTwoDecimalPlaces,
} from "../lib/tools/helpers";

import useLanguage from "../hooks/useLanguage";
import useMobile from "../hooks/useMobile";
import { useLiveFeed } from "../hooks/useSocket";
import { useUserSession } from "../hooks/useUserSession";

import Button from "../items/Button";
import GamesIcons from "../items/GamesIcons";
import Table from "../items/Table";
import RewardIcons from "@items/RewardIcons";
import ValueDisplay from "../items/ValueDisplay";

import LoadingSpinner from "./Loading/LoadingSpinner";

import ArrowUp from "../assets/icons/general/ArrowUp";
import Hidden from "../assets/icons/general/Hidden";

import st from "../styles/components/ResultFeed.module.scss";

import useWallet from "@hooks/useWallet";

import { NODE_API } from "@lib/api/api";
import usePopup from "@hooks/usePopup";

const rooms = {
  all: "GAME_HISTORY",
  user: "USER_HISTORY",
};

const ArrowDown = () => (
  <svg
    width="28"
    height="28"
    viewBox="0 0 28 28"
    fill="#7179A5"
    xmlns="http://www.w3.org/2000/svg"
  >
    <rect
      x="0.5"
      y="0.5"
      width="27"
      height="27"
      rx="8.5"
      fill="#161A33"
      stroke="#232743"
    />
    <path
      d="M14.6667 9.33333V16.78L17.92 13.5267C18.18 13.2667 18.6067 13.2667 18.8667 13.5267C18.9285 13.5883 18.9775 13.6616 19.011 13.7422C19.0444 13.8229 19.0616 13.9094 19.0616 13.9967C19.0616 14.084 19.0444 14.1704 19.011 14.2511C18.9775 14.3317 18.9285 14.405 18.8667 14.4667L14.4733 18.86C14.4117 18.9218 14.3384 18.9708 14.2578 19.0043C14.1771 19.0377 14.0907 19.055 14.0033 19.055C13.916 19.055 13.8296 19.0377 13.7489 19.0043C13.6683 18.9708 13.595 18.9218 13.5333 18.86L9.13334 14.4733C9.07162 14.4116 9.02266 14.3383 8.98926 14.2577C8.95585 14.1771 8.93866 14.0906 8.93866 14.0033C8.93866 13.916 8.95585 13.8296 8.98926 13.749C9.02266 13.6683 9.07162 13.5951 9.13334 13.5333C9.19506 13.4716 9.26833 13.4227 9.34898 13.3892C9.42962 13.3558 9.51605 13.3387 9.60334 13.3387C9.69063 13.3387 9.77706 13.3558 9.8577 13.3892C9.93835 13.4227 10.0116 13.4716 10.0733 13.5333L13.3333 16.78V9.33333C13.3333 8.96667 13.6333 8.66667 14 8.66667C14.3667 8.66667 14.6667 8.96667 14.6667 9.33333Z"
      fill="#7179A5"
    />
  </svg>
);

const RenderRow_ = (props) => {
  const L = useLanguage(["ResultFeed", "common"]);
  const { rank } = props.user;
  const { publicId } = useUserSession();

  const userNameDisplay = props.user.username ? (
    <Button
      buttonType={"link"}
      query={{
        modal: publicId === props.user.publicId ? "profile" : "user",
        user: props.user.publicId,
      }}
      active={false}
      text={props.user.username}
      classType={["chat-user-display", "live-feed"]}
    />
  ) : (
    L("hidden")
  );

  const multiplier = useMemo(() => {
    const from = props.currencyAmount;
    const to = props.currencyPayout;

    return from && to ? to / from : props.multiplier;
  }, [props.currencyAmount, props.currencyPayout, props.multiplier]);

  return (
    <>
      <div className={st["public_id"]} data-url={props.game.url}>
        <Button
          buttonType={"link"}
          query={{
            modal: "bet",
            tab: "result",
            betId: props.id,
          }}
          active={false}
          text={
            props?.game?.name?.length > 15
              ? props.game.name.slice(0, 15) + "..."
              : props.game.name
          }
          icon={
            props.game.url ? (
              <GamesIcons
                code={
                  props.game.url === "mines-game" ? "mines" : props.game.url
                }
              />
            ) : null
          }
          classType={["chat-user-display", "live-feed"]}
        />
      </div>

      <div className={st["game_name"]}>
        {rank?.name && (
          <div>
            <RewardIcons code={rank?.name?.toUpperCase()} size={"large"} />
          </div>
        )}
        {userNameDisplay} {userNameDisplay === L("hidden") ? <Hidden /> : null}
      </div>

      <div className={st["bet_amount"]}>
        <ValueDisplay
          amount={props?.currencyAmount}
          currencyCode={props?.currency}
          size="small"
        />
      </div>

      <div className={st["multiplier"]}>
        {trimToTwoDecimalPlaces(multiplier)}x
      </div>

      <div className={st["payout"]}>
        <div
          className={`${st["payout-display"]} ${st["negative"]}`}
          {...classnames(
            st,
            "payout-display",
            props.multiplier < 1 && "negative"
          )}
        >
          <span className={st["payout-value"]}>
            <ValueDisplay
              amount={props?.currencyPayout}
              currencyCode={props?.currency}
              customClass={
                multiplier < 1
                  ? st["lose-payout-value-color"]
                  : st["payout-value-color"]
              }
              size={"small"}
            />
          </span>

          {props.multiplier < 1 ? (
            <ArrowDown />
          ) : (
            <ArrowUp fill={"#7DD934"} stroke={"#7DD934"} />
          )}
        </div>
      </div>
    </>
  );
};

const RenderRow = memo(RenderRow_);

const bets_url = "v1/game-history";
const fetcher = (opts = {}) =>
  NODE_API.post(bets_url, { take: 10, ...opts }).then((res) => res.data);

const useBets = (room = rooms.all) => {
  const [bets, setBets] = useState(null);
  const { publicId } = useUserSession();
  const setMessage = usePopup();

  const mutate = useCallback(
    () =>
      fetcher(room === rooms.user ? { public_id: publicId } : undefined)
        .then(setBets)
        .catch((e) => {
          handleCatchPopup(setMessage)(e);

          setBets([]);
        }),
    [publicId, room, setMessage]
  );

  useEffect(() => {
    setBets(null);
    mutate();
  }, [mutate]);

  return { bets, mutate };
};

function ResultFeed(props) {
  const isMobile = useMobile();
  const L = useLanguage(["ResultFeed", "common"]);
  const [currentRoom, setCurrentRoom] = useState(rooms.all);
  const { walletSetting, walletBalanceList } = useWallet();
  const { hasUserData } = useUserSession();
  const { bets, mutate } = useBets(currentRoom);
  const { data: betHistory } = useLiveFeed({
    limit: 10,
    initialState: bets,
    handleMerge: () => {
      // if room is user, return true if publicId is the same as the user in the data
      return currentRoom !== rooms.user;
    },
  });

  useEffect(() => {
    if (currentRoom !== rooms.user) return;

    mutate();
  }, [walletBalanceList, currentRoom, mutate]);

  // Headers for the table component
  const headers = [
    { label: L("gameName"), className: "full" },
    { label: L("user"), className: "mobile-remove" },
    { label: L("betAmount"), className: "mobile-remove" },
    { label: L("multiplier"), className: "mobile-remove" },
    { label: L("payout"), className: "full" },
  ];

  return (
    <div
      className={`${isMobile ? st[isMobile] : null}  ${
        st["game-feed-container"]
      }`}
      {...props}
    >
      <div className={st["live-tabs"]}>
        <Button
          method={() => {
            setCurrentRoom(rooms.all);
          }}
          text={L("all_bets")}
          classType={["tab", "live-tab"]}
          active={currentRoom === rooms.all}
        />

        <Button
          method={() => {
            setCurrentRoom(rooms.user);
          }}
          text={L("my_bets")}
          classType={["tab", "live-tab"]}
          deActive={!hasUserData}
          active={currentRoom === rooms.user}
        />
      </div>

      <div className={st["container"]}>
        {betHistory?.length ? (
          <Table
            headers={headers}
            data={betHistory}
            renderRow={RenderRow}
            classType={["live-feed-table"]}
            walletSetting={walletSetting}
          />
        ) : (
          <div className={st["empty"]}>
            {betHistory?.length !== 0 ? (
              <LoadingSpinner />
            ) : (
              <div> {L("no_bets")}</div>
            )}
          </div>
        )}
      </div>
    </div>
  );
}

export default memo(ResultFeed);

export default function ArrowUp(props) {
  return (
    <svg
      width="28"
      height="28"
      viewBox="0 0 28 28"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      {...props}
    >
      <rect
        x="0.5"
        y="0.5"
        width="27"
        height="27"
        rx="8.5" 
        fill="#161A33"
        stroke="#232743"
      />
      <path
        d="M14.6667 18.6667V11.22L17.92 14.4733C18.18 14.7333 18.6067 14.7333 18.8667 14.4733C18.9285 14.4117 18.9775 14.3384 19.011 14.2578C19.0444 14.1771 19.0616 14.0906 19.0616 14.0033C19.0616 13.916 19.0444 13.8296 19.011 13.7489C18.9775 13.6683 18.9285 13.595 18.8667 13.5333L14.4733 9.14C14.4117 9.0782 14.3384 9.02917 14.2578 8.99571C14.1771 8.96226 14.0907 8.94504 14.0033 8.94504C13.916 8.94504 13.8296 8.96226 13.7489 8.99571C13.6683 9.02917 13.595 9.0782 13.5333 9.14L9.13334 13.5267C9.07162 13.5884 9.02266 13.6617 8.98926 13.7423C8.95585 13.8229 8.93866 13.9094 8.93866 13.9967C8.93866 14.084 8.95585 14.1704 8.98926 14.251C9.02266 14.3317 9.07162 14.4049 9.13334 14.4667C9.19506 14.5284 9.26833 14.5773 9.34898 14.6108C9.42962 14.6442 9.51605 14.6613 9.60334 14.6613C9.69063 14.6613 9.77706 14.6442 9.8577 14.6108C9.93835 14.5773 10.0116 14.5284 10.0733 14.4667L13.3333 11.22V18.6667C13.3333 19.0333 13.6333 19.3333 14 19.3333C14.3667 19.3333 14.6667 19.0333 14.6667 18.6667Z"
        fill="#7DD934"
      />
    </svg>
  );
}

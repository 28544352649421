// components/Table.js

import st from "../styles/items/Table.module.scss";
import useMobile from "../hooks/useMobile";
import useLanguage from "../hooks/useLanguage";
import LoadingSpinner from "../components/Loading/LoadingSpinner";

/**
 * ]@deprecated
 */
const Table = ({
  headers,
  data,
  renderRow: RowComponent,
  loading,
  classType,
  walletSetting,
}) => {
  const isMobile = useMobile();
  const L = useLanguage(["UserProfile"]);

  if (!data.length)
    return (
      <div className={st["no-data"]}>
        {loading ? <LoadingSpinner /> : L("no_data_display")}
      </div>
    );

  return (
    <div
      className={`${isMobile ? st[isMobile] : null} ${
        st["table"]
      } ${classType?.map((item) => st[item]).join(" ")}`}
    >
      <div className={`${st["row"]} ${st["heading"]}`}>
        {headers.map((header, index) => (
          <div key={index} className={st[header.className]}>
            {header.label}
          </div>
        ))}
      </div>
      <div className={st["list"]}>
        {data.map((item, index) => (
          <div key={index} className={st["row"]}>
            <RowComponent {...item} walletSetting={walletSetting} />
          </div>
        ))}
      </div>
    </div>
  );
};

export default Table;

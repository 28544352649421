export default function LifebuoyIcon({ fill = "#2099FF" , ...props}) {
  return (
    <svg
      width="32"
      height="32"
      viewBox="0 0 32 32"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      {...props}
    >
      <path
        d="M16 3C13.4288 3 10.9154 3.76244 8.77759 5.1909C6.63975 6.61935 4.97351 8.64968 3.98957 11.0251C3.00563 13.4006 2.74819 16.0144 3.2498 18.5362C3.75141 21.0579 4.98953 23.3743 6.80762 25.1924C8.6257 27.0105 10.9421 28.2486 13.4638 28.7502C15.9856 29.2518 18.5995 28.9944 20.9749 28.0104C23.3503 27.0265 25.3807 25.3603 26.8091 23.2224C28.2376 21.0846 29 18.5712 29 16C28.9964 12.5533 27.6256 9.24882 25.1884 6.81163C22.7512 4.37445 19.4467 3.00364 16 3ZM20.8875 19.4737C21.611 18.4599 21.9999 17.2455 21.9999 16C21.9999 14.7545 21.611 13.5401 20.8875 12.5262L24.45 8.965C26.0976 10.939 27.0001 13.4287 27.0001 16C27.0001 18.5713 26.0976 21.061 24.45 23.035L20.8875 19.4737ZM12 16C12 15.2089 12.2346 14.4355 12.6741 13.7777C13.1137 13.1199 13.7384 12.6072 14.4693 12.3045C15.2002 12.0017 16.0044 11.9225 16.7804 12.0769C17.5563 12.2312 18.269 12.6122 18.8284 13.1716C19.3878 13.731 19.7688 14.4437 19.9231 15.2196C20.0775 15.9956 19.9983 16.7998 19.6955 17.5307C19.3928 18.2616 18.8801 18.8864 18.2223 19.3259C17.5645 19.7654 16.7911 20 16 20C14.9391 20 13.9217 19.5786 13.1716 18.8284C12.4214 18.0783 12 17.0609 12 16ZM23.035 7.55L19.4738 11.1125C18.4599 10.389 17.2455 10.0001 16 10.0001C14.7545 10.0001 13.5401 10.389 12.5263 11.1125L8.96501 7.55C10.939 5.90237 13.4287 4.99988 16 4.99988C18.5713 4.99988 21.061 5.90237 23.035 7.55ZM7.55001 8.965L11.1125 12.5262C10.389 13.5401 10.0001 14.7545 10.0001 16C10.0001 17.2455 10.389 18.4599 11.1125 19.4737L7.55001 23.035C5.90238 21.061 4.99988 18.5713 4.99988 16C4.99988 13.4287 5.90238 10.939 7.55001 8.965ZM8.96501 24.45L12.5263 20.8875C13.5401 21.611 14.7545 21.9999 16 21.9999C17.2455 21.9999 18.4599 21.611 19.4738 20.8875L23.035 24.45C21.061 26.0976 18.5713 27.0001 16 27.0001C13.4287 27.0001 10.939 26.0976 8.96501 24.45Z"
        fill={fill}
      />
    </svg>
  );
}
